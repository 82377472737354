<template>

  <ion-header>
    <ion-toolbar>
      <ion-title>Set Item Name</ion-title>
    </ion-toolbar>
  </ion-header>

  <ion-content color="white" class="ion-padding" scroll-y="false">
    <div>
        <ion-input placeholder="Enter item name" v-model="inputData"></ion-input>
    </div> 
    <div class="btn-container"> 
      <ion-button class="" @click="saveModal(inputData)">Save</ion-button>
    </div> 
  </ion-content>
</template>

<script>
import { IonContent, modalController, IonInput, IonHeader, IonToolbar } from '@ionic/vue';

export default {
    name: 'ItemName',
    components: { IonContent, IonInput, IonHeader, IonToolbar }, 
    props: {
      title: { type: String, default: 'item Name' },
    },
    data () {
        return {
          inputData : ""
        }
    },
    setup() {
        const saveModal = (name) => {
          modalController.dismiss(name);
        };

        const closeModal = (close) => {
          modalController.dismiss(close);
        };


        return { saveModal, closeModal };
    },
    mounted(){
      this.inputData = this.title
    }
};
</script>

<style scoped lang="scss">
  ion-input {
    border: 1px solid #373636;
    --placeholder-color: #373636;
    --color: #373636;
    --padding-bottom: 10px;
    --padding-top: 10px;
    --padding-start: 10px;
    --padding-end: 10px;
  }

  ion-button {
    margin-top: 10px;
    margin-left: 0px;
  }

  .btn-container {
    text-align: center;
  }
</style>