<template>
  <ion-content color="white" fullscreen class="ion-padding" scroll-y="true">
    <div>
        <div class="close-modal" @click="closeModal">
            <uil-multiply size="20px" />
        </div>

        <div class="modal-section">
          <h1>Add an item</h1>
          <ion-searchbar v-model="current_search" animated placeholder="Search for an item" @ionChange="searchMade()"></ion-searchbar>

          <template v-if="Object.keys(section_results).length > 0">
            <ion-list-header>
              <ion-label>Items in the current section</ion-label>
            </ion-list-header>
            <ion-list lines="full">
              <ion-item v-for="item in section_results" :key="item.id">
                <ion-label>
                  <div class="item-result" @click="selectItem(item)">
                    <div class="item-result-left">
                      <h2>{{ item.name }}</h2>
                      <h3>Section: {{ item.section.name }}</h3>
                    </div>
                    <div class="item-result-right">
                      <template v-if="item.images.length > 0">
                        <ion-img :src="item.images[0].base64" class="item-image" />
                      </template>
                    </div>
                  </div>
                </ion-label>
              </ion-item>
            </ion-list>
          </template>

          <template v-if="Object.keys(search_results).length > 0">
            <ion-list-header>
              <ion-label>Other Sections</ion-label>
            </ion-list-header>
            <ion-list lines="full">
              <ion-item v-for="item in search_results" :key="item.id">
                <ion-label>
                  <div class="item-result" @click="selectItem(item)">
                    <div class="item-result-left">
                      <h2>{{ item.name }}</h2>
                      <h3>Section: {{ item.section.name }}</h3>
                    </div>
                    <div class="item-result-right">
                      <template v-if="item.images.length > 0">
                        <ion-img :src="item.images[0].base64" class="item-image" />
                      </template>
                    </div>
                  </div>
                </ion-label>
              </ion-item>
            </ion-list>
          </template>
        </div>
    </div>
    
  </ion-content>
</template>

<script>
import { IonContent, modalController, IonSearchbar, IonList, IonItem, IonImg, IonListHeader } from '@ionic/vue';
import { UilMultiply } from '@iconscout/vue-unicons'

export default {
    name: 'ItemSelect',
    props : {
      items : {},
      section_id : {
        type: Number,
        default: 0
      }
    },
    components: { IonContent, UilMultiply, IonSearchbar, IonList, IonItem, IonImg, IonListHeader }, 
    data () {
      return {
        current_search : "",
        search_results : [],
        section_results : [],
        item_added : false  
      }
    },
    methods : {
      searchMade(){
        if(this.current_search && this.current_search.trim() !== ''){
          console.log("============================")
          console.log("search", this.current_search)
          for(let search_prop in this.search_results){
            // this.search_results
            let current = this.search_results[search_prop];
            console.log(current.name.toLowerCase(), this.current_search.toLowerCase());
            if(!current.name.toLowerCase().includes(this.current_search.toLowerCase())){
            // if(current.name.toLowerCase().indexOf(this.current_search.toLowerCase() > -1)){
              console.log("hello")
              delete this.search_results[current.id];
            }
          }

          for(let section_prop in this.section_results){
            // this.search_results
            let current = this.section_results[section_prop];
            console.log(current.name.toLowerCase(), this.current_search.toLowerCase());
            if(!current.name.toLowerCase().includes(this.current_search.toLowerCase())){
            // if(current.name.toLowerCase().indexOf(this.current_search.toLowerCase() > -1)){
              delete this.section_results[current.id];
            }
          }
          // this.search_results.filter((item) => {
          //   return (item.name.toLowerCase().indexOf(this.current_search.toLowerCase()) > -1);
          // })
          // this.section_results = this.section_results.filter((item) => {
          //   return (item.name.toLowerCase().indexOf(this.current_search.toLowerCase()) > -1);
          // })
        } else {
          this.setupModal()
        }
      },
      closeModal(){
        modalController.dismiss();
      },
      resetSearchResults(){
        this.search_results = this.items
      },
      selectItem(item){
        modalController.dismiss(item)
      },
      setupModal(){
        this.section_results = {};
        this.search_results = {};
        if(this.section_id != 0 && this.section_id != null){
          console.log(this.section_id, this.items)
          for(let item_prop in this.items){
            let item = this.items[item_prop];
            if(item.section.id == this.section_id){
              this.section_results[item.id] = item;
            } else {
              this.search_results[item.id] = item;
            }
          }
          // this.section_results.sort((a, b) => (a.name > b.name) ? 1 : -1)
        } else {
          this.search_results = this.items
          // this.search_results.sort((a, b) => (a.name > b.name) ? 1 : -1)
        }
        console.log(this.search_results)
        console.log(this.section_results)
      },
    },
    mounted(){
      this.setupModal();
    }
};
</script>

<style scoped lang="scss">
  .modal-section {
    h1 {
      margin-top: 0px;
      padding: 0px 12px;
    }
  }

  .close-modal {
    top: 16px;
  }

  ion-searchbar {
    --border-radius: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  
  ion-list {
    margin: 0px 12px;
    ion-item {
      // --padding-start: 0px;
      --background: #ffffff;
      --border-width: 0.55px 0 0px 0 !important;
      --padding-start: 12px;
      --padding-end: 12px;
      --inner-padding-end: 0px;
    }
  }

  ion-label {
    padding-left: 0px;
    margin-right: 0px;
  }

  .item-result {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .item-image {
      width: 50px;
      height: auto;
    }
    h2 {
      font-weight: 600;

    }
  }

  ion-list-header {
    padding: 0px 12px;
    ion-label {
      padding-left: 0px;
      text-transform: uppercase;
      color: #373636;
      font-family: 'Raleway', sans-serif;
      font-size:  18px;
      font-weight: 600;
      margin-top: 20px;
      margin-bottom: 10px;
    }
  }
</style>